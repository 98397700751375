.dashNav a {
    text-decoration: none;
    font-size: 18px;
    margin: 0;
    padding: 6px 24px;
    color: #2C3E50;
    cursor: pointer;
    transition: 0.3s;
}
.dashNav a:hover {
    color: #FFF;
    background-color: #333;
}
